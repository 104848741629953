@import 'variable';
*,
:before,
:after {
  box-sizing: border-box;
}
body {
  &.indirect-tax {
    --primary-Main: #04724d;
  }
}
/*container start here*/
.container {
  max-width: @container;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media@screen1260 {
    padding: 0 24px;
  }
  @media@screen1170 {
    padding: 0 20px;
  }

  @media@screen767 {
    padding: 0 16px;
  }
}
.container-fluid {
  width: 100%;
  margin: auto;
  padding: 0 56px;
  @media@screen1170 {
    padding: 0 20px;
  }

  @media@screen768 {
    padding: 0 16px;
  }
}
/*container end here*/

.height-x-header {
  min-height: calc(100vh - 232px);
}
