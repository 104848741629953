@import '../variable.less';

.plan-notification {
  display: flex;
  padding: 10px 40px !important;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--primary-primary-tint-1);
  .ant-typography {
    color: var(--textDark);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    span {
      color: var(--textDark);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
}

.dashboard-content-holder {
  display: flex;
  flex-direction: column;
  .dashboard-content-wrapper {
    gap: 24px;
    .section-1 {
      .ant-row {
        .color-box {
          display: flex;
          width: 24px;
          height: 24px;
          padding: 1px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          margin-right: 9px;
        }
      }
    }
  }
}

.ant-divider-horizontal {
  margin: 0;
}

.statistic-cards {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  .statistic {
    .value {
      color: var(--primary-Main);
    }
  }
}

.sync-calendar {
  .ant-typography {
    color: var(--text-dark);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .ant-flex {
    @media @screen1170 {
      flex-wrap: wrap;
    }
  }
}

.ant-color-picker-trigger {
  border: none;
}

.ant-card .ant-card-body {
  padding: 16px;
  width: 100%;
}

.color-blue {
  color: #006fc0;
}

.color-red {
  color: #e64e2c;
}

.color-orange {
  color: #fa8c16;
}

.bg-blue {
  background-color: #006fc0;
}

.bg-red {
  background-color: #e64e2c;
}

.bg-orange {
  background-color: #fa8c16;
}

.head {
  color: var(--character-primary-85);
}

.plans-container {
  &.ant-modal {
    .ant-modal-content {
      padding: 32px;
      background: var(--background-2);
    }
    .plans-content {
      .plans-text {
        width: 800px;
        margin-top: 30px;
        .main-text {
          position: relative;
          width: max-content;
          margin: auto;
          .three-lines-svg {
            position: absolute;
            width: 57.229px;
            height: 36.266px;
            left: -11%;
            top: -28%;
          }
          h1 {
            color: var(--primary-primary-shade-1);
            font-size: 32px;
            font-weight: 600;
            margin-top: 16px;
            margin-bottom: 0px;
          }
          .round-arrow {
            position: absolute;
            right: calc(-148px + 14.88px);
            bottom: 30%;
          }
        }
        p {
          margin: 0;
        }
      }
      .plans-card-content {
        .card {
          width: min-content;
          .title {
            color: var(--primary-Shade-01);
            font-size: 32px;
            font-weight: 700;
            margin: 0;
          }
          .plans-duration {
            color: var(--text-medium);
          }
          p {
            margin: 0;
            font-size: 12px;
            padding: 12px;
            border-radius: 8px;
            background: var(--background-2);
          }
          .ant-btn {
            min-width: 190px;
          }
          .btn-current-plan {
            position: absolute;
            right: 16px;
            top: -25px;
            gap: var(--Large-Radius, 8px);
            border-radius: 8px;
            &.gst {
              border: 1.5px solid var(--green);
              background: var(--tint-green);
            }
            border: 1.5px solid var(--primary-Main);
            background: var(--primary-Tint-02);
          }
        }
      }
    }
  }
  .ant-modal .ant-modal-content {
    padding: 32px;
  }
}

.active-plan {
  background: var(--primary-Main);
  color: var(--color-white);

  .card .price {
    color: var(
      --color-white
    ) !important; // use important as antd's inline css won't override
  }
  .ant-btn {
    background: var(--color-white);
    color: var(--color-black);
    &:hover {
      background: var(
        --color-white
      ) !important; // use important as antd's inline css won't override
      color: var(--color-black) !important;
    }
  }
  .card .plans-duration {
    color: var(
      --primary-Tint-02
    ) !important; // use important as antd's inline css won't override
    &.gst {
      color: var(--tint-green) !important;
    }
  }
}
