.noticeboard-slider {
  padding: 40px 0;
  .slick-dots li button {
    background: #37455f;
  }
  .slick-dots li.slick-active button {
    background: var(--system-Links);
  }
  .notice-board {
    .tax-category-judge.notice-board-redirect-section {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .notice-board-container {
      @media@screen991 {
        gap: 24px;
      }
      @media@screen767 {
        flex-direction: column;
        padding-bottom: 20px;
      }
      .vector {
        top: 0;
        left: 239px;
        position: absolute;
        width: 119.277px;
        height: 111.976px;
        transform: rotate(44.995deg);
        @media@screen767 {
          display: none;
        }
      }
      .calendar {
        max-width: 519px;
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }
      .text-medium {
        color: var(--text-medium);
      }
      .text-dark {
        color: var(--textDark);
      }
      h2.semibold {
        font-size: 34px;
        line-height: 44px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        @media@screen767 {
          font-size: 28px;
          line-height: 40px;
        }
      }
      h4.medium {
        font-size: 20px;
        line-height: 30px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        @media@screen767 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
.notice-board-feature {
  background: var(--primary-primary-shade-1);
  padding: 80px 0;
  color: var(--Background-Background_01);
  @media@screen991 {
    padding: 60px 0;
  }
  h2 {
    text-align: center;
    font-size: 34px;
    line-height: 44px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    margin-bottom: 60px;
    @media@screen767 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 40px;
    }
  }
  .feature-card {
    display: flex;
    gap: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .icon {
      height: 32px;
      width: 32px;
      background: #fff;
      border-radius: 6px;
      flex-shrink: 0;
    }
    h4 {
      font-size: 22px;
      line-height: 28px;
      font-style: normal;
      font-weight: 500;
      margin: 0;
    }
    p {
      margin-top: 0;
    }
  }
}
footer {
  background: #fff;
  padding: 40px 0;
  .footer-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    @media@screen991 {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
    .footer-top-left {
      @media@screen991 {
        text-align: left;
      }
      span {
        display: inline-block;
      }
      p {
        margin: 0;
      }
      a {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--textDark);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 4px 0;
      }
    }
    .footer-top-right {
      @media@screen991 {
        margin-top: 20px;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 32px;
        list-style: none;
        padding: 0;
        margin: 0;
        @media@screen991 {
          gap: 16px;
        }
        @media@screen767 {
          gap: 8px;
          flex-wrap: wrap;
        }
        li {
          a {
            font-size: 16px;
            color: var(--textDark);
            @media@screen767 {
              font-size: 14px;
            }
            &:hover {
              color: var(--system-Links);
            }
          }
        }
      }
    }
  }
  .logicwind-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    @media@screen991 {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
}

.cms-page {
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  p {
    font-size: 16px;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 16px;
    }
  }
}

.content-center {
  max-width: 1168px;
  margin: auto;
}

.btn-wrapper {
  display: flex;
  gap: 24px;
  @media@screen991 {
    gap: 16px;
  }
  @media@screen767 {
    gap: 12px;
  }
}
.feature-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  @media@screen991 {
    flex-direction: column;
    gap: 30px;
  }
  .feature-banner {
    flex: 1;
    img {
      width: 100%;
      @media@screen991 {
        max-width: 600px;
        margin: auto;
      }
    }
  }
  .feature-content {
    flex: 1;
  }
}
