@import 'variable';

#root {
  font-family: 'Poppins';
}

.ant-layout {
  min-height: 100vh;
}
//=========================================
/********** margin padding function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 2, 4, 8, 16, 20, 24, 32, 40, 48, 64;
@font-size-array: 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }

    .gap-@{value} {
      gap: ~'@{value}px';
    }
  }
}
// font-size classes
.make-font-size() {
  .for(@font-size-array);

  .-each(@value) {
    .fs-@{value} {
      font-size: ~'@{value}px';
    }
  }
}

.make-space();
.make-font-size();

//=========================================
/********** margin padding function end here **************/
//=========================================
.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen767 {
    display: block;
  }
}

.pointer {
  cursor: pointer;
}

.pointer-not-allowed {
  cursor: not-allowed;
}

.text {
  cursor: text;
}

.grab {
  cursor: grab;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-grid {
  display: -webkit-box;
  display: -ms-grid;
  display: grid;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-self-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.max-width-164 {
  max-width: 164px;
}

.max-width-430 {
  max-width: 430px;
}

.full-height {
  height: 100%;
}

.calendar-height {
  height: 260px;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

// gap classes
.make-gap() {
  .for(@values-array);

  .-each(@value) {
    .gap-@{value} {
      gap: ~'@{value}px';
    }
  }
}

.make-gap();
//float classes

.float-right {
  float: right;
}

.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

.bodyXl(@weight: 400) {
  font-size: 20px;
  font-style: normal;
  font-weight: @weight;
  line-height: 30px;
}

.bodyL(@weight: 400) {
  font-size: 18px;
  font-style: normal;
  font-weight: @weight;
  line-height: 28px;
}

.bodyM(@weight: 400) {
  font-size: 16px;
  font-style: normal;
  font-weight: @weight;
  line-height: 24px;
}

.bodyBase(@weight: 400) {
  font-size: 14px;
  font-style: normal;
  font-weight: @weight;
  line-height: 20px;
}

.bodyMeta(@weight: 400) {
  font-size: 12px;
  font-style: normal;
  font-weight: @weight;
  line-height: 18px;
}

.bodyMicro(@weight: 400) {
  font-size: 10px;
  font-style: normal;
  font-weight: @weight;
  line-height: 14px;
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

:hover::-webkit-scrollbar-thumb {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

.mobile-restriction-screen {
  height: calc(100vh - 72px);
  & p {
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    color: var(--text-medium);
    margin: 0px;
  }
}

.ant-table-wrapper {
  .ant-table-content {
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
  }
  .ant-table {
    overflow: auto;
    border: 1px solid var(--stroke-dark);
    border-radius: 8px;
  }
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 12px 16px;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 16px;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  color: var(--textDark);
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  width: 36px;
}
.ant-col {
  padding: 0px !important;
}
.table-card {
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    width: 36px;
  }
}

.text-size-12 {
  font-size: 12px;
}

.common-search-input.ant-input-affix-wrapper {
  width: 256px;
  padding: 8px 12px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-02);
  background: var(--Background-Background_01);
  color: #777777;
  &:hover {
    border: 1px solid @primary;
  }
  &.width-full {
    width: 100%;
  }
}

.multi-pdf {
  max-width: 164px;
  word-break: break-all;
  display: inline-block;
}

.rangePicker {
  min-width: 232px;
}

.stroke-light-border {
  border: 1px solid var(--stroke-light);
  border-radius: 8px;
}

.capitalize-first {
  text-transform: lowercase;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.ant-popover > .ant-popover-arrow {
  display: none;
}

.disconnect-btn {
  .bodyMeta(600);
  cursor: pointer;
}

.gst-tabs {
  .ant-tabs-nav {
    margin-bottom: 32px;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          flex: 1;
          justify-content: center;
          width: auto;
        }
      }
    }
  }
}
.rotate {
  -webkit-animation: rotate 5s normal linear infinite;
  animation: rotate 5s normal linear infinite;
  display: inline-block;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
