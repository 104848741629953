.bodyXl(@weight: 400) {
  font-size: 20px;
  font-style: normal;
  font-weight: @weight;
  line-height: 30px;
}

.bodyL(@weight: 400) {
  font-size: 18px;
  font-style: normal;
  font-weight: @weight;
  line-height: 28px;
}

.bodyM(@weight: 400) {
  font-size: 16px;
  font-style: normal;
  font-weight: @weight;
  line-height: 24px;
}

.bodyBase(@weight: 400) {
  font-size: 14px;
  font-style: normal;
  font-weight: @weight;
  line-height: 20px;
}

.bodyMeta(@weight: 400) {
  font-size: 12px;
  font-style: normal;
  font-weight: @weight;
  line-height: 18px;
}

.bodyMicro(@weight: 400) {
  font-size: 10px;
  font-style: normal;
  font-weight: @weight;
  line-height: 14px;
}

.buttonB(@weight: 400) {
  font-size: 16px;
  font-style: normal;
  font-weight: @weight;
  line-height: 16px;
}

.buttonM(@weight: 400) {
  font-size: 18px;
  font-weight: @weight;
  line-height: 18px;
}

.buttonL(@weight: 400) {
  font-size: 20px;
  font-weight: @weight;
  line-height: 20px;
}
