.filters {
  @media @screen1440, @screen1500, @screen1750 {
    .max-width {
      max-width: 200px;
    }
  }
  @media @screen1080, @screen1260, @screen1350 {
    flex-wrap: wrap;
    .max-width {
      max-width: 268px;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}
.custom-active {
  .filters {
    flex-wrap: wrap;
    .ant-form-item {
      max-width: 255px;
    }
  }
}

.statistic {
  padding: 16px;
  align-items: center;
  flex: 1 1;
  gap: 16px;
  min-width: 196px;
  justify-content: flex-start;
  &:hover {
    border-radius: 8px;
    background: var(--background-2);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }
}
.icon {
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--stroke-dark);
  background: var(--background-2);
}
.status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  span {
    color: var(--Neutral-08);
  }
  .value {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
  }
}

.ant-btn-primary:disabled {
  opacity: 0.4;
}

.ant-btn-default:disabled {
  opacity: 0.4;
  background: none;
  color: var(--primary-Main);
  cursor: not-allowed !important; //for override antd css
}

.ant-btn-link:disabled {
  opacity: 0.4;
  color: var(--primary-Main);
}

a:hover {
  color: var(--system-Links);
}

.my-breadcrumb {
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  a {
    color: @breadcrumbLink;
    text-transform: capitalize;

    &.active-link {
      color: var(--textDark);
    }
  }
}

.heading {
  color: var(--primary-Main) !important; //to override antd style
  .bodyM(600);
}

.select {
  width: 268px;
}

.underline-btn > span {
  text-decoration: underline;
}

.title {
  color: var(--text-medium) !important; //to override antd css
}

.ask-ai-btn {
  border-radius: 16px;
  padding: 2px 4px;
  gap: 2px;
  height: auto;
  & .ant-btn-icon {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    margin-inline-end: 2px !important;
  }
  & span {
    font-size: 8px;
    line-height: 10px;
  }

  &:hover {
    border-radius: 100px;
    border: 0.5px solid var(--border-ai);
    background: var(--background-ai) !important; //to override antd style
    box-shadow: 0px 0px 6px 0px rgba(0, 81, 255, 0.22);
    color: var(--color-white) !important; //to override antd style
  }
}

.custom-pagination-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ul {
    li.ant-pagination-prev {
      margin-right: 16px;
    }
  }
}

.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination .ant-pagination-next button,
.ant-pagination .ant-pagination-prev button {
  background: none;
}

.custom-pagination-button:hover {
  background-color: #f0f0f0;
}

.template-pagination .ant-pagination-item {
  border-radius: 8px;
}

.ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.submission-card {
  .check-icon {
    border-radius: 80px;
    border: 1px solid var(--stroke-dark);
    background: var(--Background-Background_02);
    width: 52px;
    height: 52px;
    padding: 12px;
    margin-right: 16px;
  }
  .detail {
    .items-title {
      font-size: 12px;
    }
    .date {
      color: var(--text-medium);
      font-size: 12px;
    }
  }
  .files-detail {
    a,
    .ant-btn > span {
      font-size: 12px;
    }
    .ant-btn {
      padding: 4px 12px;
    }
  }
}

.value {
  font-size: 16px;
}

.empty-text {
  width: 1168px;
  position: sticky;
  left: 0px;
  overflow: hidden;
  color: var(--text-dark);
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 24px 16px;
}

.non-clickable {
  color: gray;
  pointer-events: none;
  text-transform: capitalize;
}

.h-512 {
  .ant-modal-content {
    height: 512px;
  }
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
  padding: 24px 16px;
}

.ant-space-item {
  cursor: pointer;
}

.tick-gif {
  width: 128px;
  height: 128px;
}

.calendar-container {
  .ant-picker-calendar {
    .ant-picker-body {
      padding: 12px 8px;
      table {
        tbody {
          tr {
            td {
              &.ant-picker-cell {
                &.ant-picker-cell-in-view {
                  span {
                    color: rgba(0, 0, 0, 0.85);
                  }
                }
                span {
                  color: rgba(0, 0, 0, 0.25);
                }
              }
            }
            td > span {
              border: 1px solid transparent;
            }
            td.ant-picker-cell-today > span {
              border: 1px solid var(--border-calendar);
            }
            td.ant-picker-cell-selected > span {
              border: 1px solid var(--border-ai);
            }
            span.ant-badge.ant-badge-status.ant-badge-not-a-wrapper {
              display: flex;
              text-align: center;
              height: 24px;
              width: 24px;
              margin: auto;
              justify-content: center;
              padding: 1px !important; // Override antd class
              .ant-badge-status-dot {
                display: none;
              }
              .ant-badge-status-text {
                margin-left: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.ant-btn {
  padding: 0px 16px;
}
.ant-btn-sm {
  padding: 0px 7px !important;
}
.ant-btn.ask-ai-btn {
  padding: 2px 4px;
}

a {
  color: var(--system-Links);
}

.ant-form-item .ant-form-item-label > label {
  color: var(--textDark);
}

.table-card {
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    width: 36px;
  }
}

.track-return-status {
  .tables {
    .ant-table-wrapper {
      margin-top: 8px;
    }
    .ant-table-body {
      scrollbar-width: thin;
      scrollbar-color: #eaeaea transparent;
      scrollbar-gutter: stable;
    }
    .ant-typography {
      color: var(--text-medium);
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.ant-collapse.table-collapse {
  button {
    border-color: var(--stroke-dark);
  }
  .title {
    font-weight: 600;
  }
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 8px 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 8px 0 0 0;
      }
    }
  }
}
.filter-form {
  .ant-picker {
    min-width: 240px;
  }
}

.logout-popup-btn {
  width: 100%;
  height: 52px;
  padding: 16px 32px 16px 32px;
  margin-top: 12px;
  gap: 6px;
  border-radius: 8px;
  background-color: var(--system-Links);
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-white);
}

.notices-tabs {
  .ant-badge {
    padding: 0px 6px;
    display: flex;
    align-items: center;
    border-radius: 48px;
    background: var(--Background-Background_01);
    box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.15);
    .ant-scroll-number {
      color: var(--text-dark,);
    }
  }
}
