@import '../variable.less';

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;

  & .left-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & .ant-btn {
      display: flex;
      align-items: center;
    }
  }
  .right-buttons {
    display: flex;
    align-items: center;
    & .ant-btn {
      display: flex;
      align-items: center;
    }

    &.left-buttons {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .left-buttons {
      width: 100%;
      margin-bottom: 8px;
      margin-top: 8px;
      flex-direction: column;

      .ant-btn {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.statistic-card {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.eye-action {
  width: 10%;
  display: flex;
  padding: 16px 0px;
  align-items: flex-end;
  justify-content: center;
}

.remove-modal {
  .ant-modal-footer {
    gap: 8px;
  }
}

.empty {
  margin-top: 50px;
  font-style: normal;
  line-height: 28px;
  color: var(--text-medium);
}

.notices-card {
  .description {
    margin: 0;
  }
}
