header {
  position: sticky;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid var(--stroke-dark);
  height: 72px;
  background: var(--color-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  .header-wrapper {
    .d-flex;
    .justify-between;
    .align-center;
    gap: 24px;
    width: 100%;

    @media @screen1460 {
      gap: 16px;
    }
    @media @screen1170 {
      gap: 12px;
    }
    .logo {
      font-weight: 600;
      font-size: 20px;
      cursor: pointer;
      display: flex;
      svg {
        @media @screen375 {
          height: 25px;
          width: auto;
        }
      }
    }

    .header-left {
      height: 64px;
      flex: 1;
      .d-flex;
      .align-center;

      .menu-list {
        width: 100%;

        nav {
          width: 100%;
          .d-flex;
          .justify-between;
          gap: 24px;

          @media @screen1170 {
            gap: 12px;
          }

          li {
            height: 60px;
            .d-flex;
            align-items: center;
            position: relative;

            @media @screen1460 {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }

            &.active-tab {
              .item {
                color: var(--primary-Main);
                font-weight: 500;
              }

              &::before {
                content: '';
                position: absolute;
                bottom: -1px;
                background: '';
                height: 4px;
                width: 100%;
              }
            }
            a {
              line-height: 24px;
              text-decoration: none;
              color: var(--color-black);
              display: block;

              svg {
                display: block;

                @media @screen1024 {
                  height: 30px;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }

    .mobile-menu-list {
      position: fixed;
      background-color: var(--color-white);
      width: 100%;
      left: 0;
      top: 54px;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      height: auto;
      max-height: calc(100% - 54px);
      overflow: auto;
      transition: all 0.3s ease-in-out;
      ul {
        .d-flex;
        flex-direction: column;
        height: 100%;
        row-gap: 12px;
        font-size: 16px;
        list-style: none;
        margin: 0;
        li {
          padding: 8px;
          a,
          .sign-out-link {
            width: 100%;
            cursor: pointer;
            &:hover {
              color: var(--primary-Main);
            }
          }
          &.active-tab {
            .item {
              color: var(--primary-Main);
              font-weight: 500;
            }
          }
        }
      }

      p {
        padding: 8px;
      }

      &.open {
        opacity: 1;
        visibility: visible;
      }

      .ant-collapse-header {
        flex-direction: row-reverse;
        align-items: center;
        gap: 12px;
        width: fit-content;
        padding: 8px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      .ant-collapse {
        border-radius: 0;
      }
      .ant-collapse-item {
        background-color: var(--background-2);
      }
      .ant-collapse-content-box {
        padding: 0;
      }
      .menu-sub-list-items {
        row-gap: 0;
      }
    }

    .header-right {
      .ant-avatar {
        width: 48px;
        height: 48px;
        border-radius: 48px;
      }
      @media @screen1024 {
        .d-flex;
        align-items: center;
        gap: 24px;
      }

      ul {
        .d-flex;
        align-items: center;
        gap: 16px;

        @media @screen1460 {
          gap: 16px;
        }

        li {
          svg {
            width: 24px;
          }

          a {
            display: inline;
          }
        }
      }
    }

    img {
      max-height: 100%;
    }

    .menu-list-items {
      .d-flex;
      align-items: center;
      height: 100%;
      gap: 24px;

      @media @screen1460 {
        gap: 16px;
      }

      @media @screen1170 {
        gap: 12px;
      }
    }

    .menu-list-section {
      .d-flex;
      align-items: center;
    }
  }
  .btn-wrapper {
    .m-hide {
      display: block;
      @media @screen1024 {
        display: none;
      }
    }
  }
  .m-hide {
    .d-flex;

    @media @screen1024 {
      display: none;
    }
  }

  .d-hide {
    display: none;

    @media @screen1024 {
      display: block;
    }
  }
}
