.ant-modal .ant-modal-content {
  padding: 16px;
  .ant-modal-header {
    margin-bottom: 24px;
  }
  .ant-modal-body {
    .ant-form .ant-form-item {
      margin-bottom: 24px;
      .ant-btn {
        margin-bottom: 0;
      }
    }
  }

  .ant-modal-footer {
    flex-direction: row-reverse;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 0px;
    & .ant-btn {
      width: 100%;
      margin: 0;
    }
  }
}

.ant-modal .ant-modal-title {
  color: var(--textDark);
}

.attachment-modal {
  .ant-modal-body {
    height: 430px;
    overflow: auto;
  }
}
